import React, { useEffect, useState } from "react";
import {
  fetchPresets,
  fetchPresetById,
  createPreset,
  updatePreset,
  deletePreset,
  uploadImage,
} from "./api"
import LoadingScreen from "./LoadingScreen"
import PresetModal from "./PresetModal"

function LivingRoomPresets() {
  const [presets, setPresets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPreset, setCurrentPreset] = useState({
    name: "",
    image: null,
    furniture: [],
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await fetchPresets();
        setPresets(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleOpenNew = () => {
    setCurrentPreset({
      name: "",
      image: null,
      furniture: [],
    });
    setIsEditing(false);
    setShowModal(true);
  };

  const handleOpenEdit = async (presetId) => {
    try {
      setModalLoading(true);
      setShowModal(true);
      const presetData = await fetchPresetById(presetId);
      setCurrentPreset(presetData);
      setIsEditing(true);
    } catch (err) {
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

const handleSubmitPreset = async () => {
  try {
    setModalLoading(true);

    // Prepare the data to be sent to the API
    const presetData = {
      name: currentPreset.name,
      imageUrl: currentPreset.image,  // Already uploaded image URL
      furniture: JSON.stringify(currentPreset.furniture),
    };

    if (isEditing) {
      await updatePreset(currentPreset.id, presetData);
    } else {
      await createPreset(presetData);
    }

    setShowModal(false);

    // Refresh the presets list after successful save
    const updatedPresets = await fetchPresets();
    setPresets(updatedPresets);

  } catch (err) {
    console.error("Error saving preset:", err);
  } finally {
    setModalLoading(false);
  }
};



  const handleAddFurniture = () => {
    setCurrentPreset((prevPreset) => ({
      ...prevPreset,
      furniture: [
        ...prevPreset.furniture,
        {
          id: Date.now(),
          CODE: "",
          "PRODUCT NAME": "",
          "FINISH/COLOUR": "",
          SUPPLIER: "",
          LENGTH: 0,
          HEIGHT: 0,
          DEPTH: 0,
          COST: 0,
          URL: "",
          "DATE ADDED": new Date().toISOString().split("T")[0],
          "CONFIRMED ONLINE": false,
        },
      ],
    }));
  };

  const handleRemoveFurniture = (fid) => {
    setCurrentPreset((prevPreset) => ({
      ...prevPreset,
      furniture: prevPreset.furniture.filter((f) => f.id !== fid),
    }));
  };

  const handleFurnitureChange = (fid, key, value) => {
    setCurrentPreset((prevPreset) => ({
      ...prevPreset,
      furniture: prevPreset.furniture.map((f) =>
        f.id === fid ? { ...f, [key]: value } : f
      ),
    }));
  };

const handleChangeImage = async (e) => {
  const file = e.target.files[0];
  if (file) {
    setCurrentPreset((prev) => ({
      ...prev,
      image: null,  // Clear current image
      imagePreview: null,  // Clear preview
      imageUploading: true,  // Show loading animation
    }));

    try {
      const result = await uploadImage(file);

      setCurrentPreset((prev) => ({
        ...prev,
        image: result.imageUrl,  // Save uploaded image URL
        imagePreview: result.imageUrl,  // Display image
        imageUploading: false,  // Hide loading indicator
      }));
    } catch (err) {
      console.error("Error uploading image:", err);
      setCurrentPreset((prev) => ({
        ...prev,
        imageUploading: false,  // Hide loading indicator
      }));
      alert("Failed to upload image. Please try again.");
    }
  }
};



  if (loading) return <LoadingScreen />;

  return (
    <div className="mx-auto max-w-7xl p-4">
      <h1 className="text-3xl font-bold mb-6">Living Room Presets</h1>

      <button
        onClick={handleOpenNew}
        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
      >
        + Create New Preset
      </button>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-6">
        {presets.map((preset) => (
          <div
            key={preset.id}
            className="border rounded-lg shadow hover:shadow-lg transition-shadow cursor-pointer overflow-hidden"
            onClick={() => handleOpenEdit(preset.id)}
          >
            <img
              src={preset.image}
              alt={preset.name}
              className="w-full h-48 object-contain"
            />
            <div className="p-4">
              <p className="font-semibold text-lg">{preset.name}</p>
            </div>
          </div>
        ))}
      </div>

      <PresetModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        preset={currentPreset}
        setPreset={setCurrentPreset}
        onSubmit={handleSubmitPreset}
        isEditing={isEditing}
        loading={modalLoading}
        onAddFurniture={handleAddFurniture}
        onRemoveFurniture={handleRemoveFurniture}
        onFurnitureChange={handleFurnitureChange}
        onImageChange={handleChangeImage}
      />
    </div>
  );
}

export default LivingRoomPresets