import React, {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom'
import { Check, Heart, ChevronLeft, Star, Ruler, ChevronDown, Settings, CreditCard, FileText, LogOut } from "lucide-react"
import { AuthContext } from '../components/AuthContext.js'
import LoadingScreen from '../components/LoadingScreen'
import Footer from "../components/Footer.js";

const baseDomain =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API

function LivingRoomDesign() {
  const { user } = useContext(AuthContext)
  const { presetId } = useParams()
  const [loading, setLoading] = useState(true)
  const [preset, setPreset] = useState({})
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('presets')

  useEffect(() => {
      window.scrollTo(0, 0)
  }, [60])

  const fetchPreset = async (presetId) => {
    setLoading(true)
    const res = await fetch(baseDomain+"/api/presets/preview/"+presetId)
    if (!res.ok) {
      throw new Error("Failed to fetch presets");
    }
    const x = await res.json()
    setPreset(x)
    setLoading(false)
  }

  useEffect(() => {
    fetchPreset(presetId)
  }, [presetId, user?.id])

  if(loading) return <LoadingScreen/>


  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col">
        {/* Header */}
      <div className="flex justify-between items-center w-full px-6 pb-2">
        <a href="/" className="text-purple-800 hover:underline">
          &larr; Back to Homepage
        </a>
      </div>

        <div className="md:w-full p-4 flex flex-col md:flex-row justify-center items-center">
          <div className="w-full md:w-1/2 flex-col justify-center">
            <img src={preset.image} alt="Living Room Design" className="w-full max-w-3xl object-cover rounded-lg" />
            <h1 className="text-2xl font-bold mb-2 text-center pt-8">{preset.name}</h1>
          </div>
          <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center md:text-left p-8">
      <div className="flex flex-wrap justify-center gap-8 w-full">
        <div className="max-w-md">
          <div className="less_cool_button_with_shadow rounded-lg border bg-white text-gray-900 shadow-sm">
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="text-3xl font-semibold leading-none tracking-tight">Unlock all presets now!</h3>
              <p className="text-sm text-gray-500">Pays for itself in furniture cost and time savings!</p>
            </div>
            <div className="p-6 pt-0 grid gap-4">
              <div className="flex items-baseline justify-center gap-1">
                <span className="text-4xl font-bold">$6</span>
                <span className="text-gray-500">AUD</span>
              </div>
              <ul className="grid gap-2 py-4">
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Access to all current products</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Access to all future products</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Regular updates and new features</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Time-saving designs</span>
                </li>
              </ul>
            </div>
            <div className="flex items-center p-6 pt-0">
              <button className="w-full inline-flex items-center justify-center cool_button py-2 px-6 rounded-full relative" onClick={() => window.location.href='/join'}>
                Join And Unlock Now!
              </button>
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>


        {/* Furniture List */}
        <div className="p-4 mt-4">

      <div className="overflow-x-auto px-12 pb-12">
        <h3 className="text-2xl font-semibold mb-2 pt-8">Login or Join to unlock the list of furniture in this preset:</h3>

        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider filter">Furniture</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider filter ">Size</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider filter">Price</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider filter">Where To Buy It</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {preset.furniture?.map((item, index) => (
              <tr key={index} className="transition duration-300 ease-in-out hover:bg-gray-50">
                <td className="py-4 px-4 whitespace-nowrap filter blur-sm">
                  <div className="text-sm font-medium text-gray-900">{item['PRODUCT NAME']}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-center filter blur-sm">
                  <div className="text-sm text-gray-500">{item.WIDTH}x{item.HEIGHT}x{item.DEPTH}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-center filter blur-sm">
                  <div className="text-sm font-semibold text-gray-900">{item.COST}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-sm font-medium text-center filter">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cool_button bg-purple-300 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out inline-block cursor-pointer"
                  >
                    Unlock to see the link
                  </a>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>

        </div>
  
      <Footer top_color={'#f9f6f3'}/>


    </div>
  )
}

export default LivingRoomDesign