import React from 'react';

const TermsOfService = () => {
  return (
    <div className="mx-auto px-4 py-8 bg-[#f9f6f3] w-screen">
      <div className="max-w-6xl mx-auto rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-4 text-gray-800">Terms of Service</h1>

        <p className="mb-4 text-gray-700">
          Last updated: January 26, 2025
        </p>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">1. Introduction</h2>
          <p className="text-gray-700">
            Welcome to Room Customiser ("we", "our", "us"). These Terms of Service ("Terms") govern your access to and use of our website [roomcustomiser.com], including any content, functionality, and services offered on or through the Site.
          </p>
          <p className="text-gray-700 mt-2">
            By using our Site, you accept and agree to be bound and abide by these Terms. If you do not want to agree to these Terms, you must not access or use the Site.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">2. Eligibility</h2>
          <p className="text-gray-700">
            You must be at least 18 years old to use our services. By agreeing to these Terms, you represent and warrant that you are at least 18 years of age.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">3. User Accounts</h2>
          <p className="text-gray-700">
            To access certain features of our Site, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate.
          </p>
          <p className="text-gray-700 mt-2">
            You are responsible for safeguarding your password and other login credentials. You agree to notify us immediately of any unauthorized use of your account.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">4. Acceptable Use</h2>
          <p className="text-gray-700">
            You agree not to use the Site for any unlawful purpose or in any way that might harm, damage, or disparage any other party.
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>Do not engage in illegal activities.</li>
            <li>Do not transmit harmful or malicious software.</li>
            <li>Respect intellectual property rights.</li>
            <li>Avoid transmitting unsolicited advertisements or spam.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">5. Intellectual Property</h2>
          <p className="text-gray-700">
            All content, trademarks, service marks, logos, and other intellectual property displayed on the Site are the property of Room Customiser or their respective owners. You are not permitted to use, reproduce, or distribute any of these without our explicit consent.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">6. Termination</h2>
          <p className="text-gray-700">
            We reserve the right to terminate or suspend your access to the Site immediately, without prior notice or liability, for any reason, including if you breach the Terms.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">7. Limitation of Liability</h2>
          <p className="text-gray-700">
            To the fullest extent permitted by law, Room Customiser shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>Your access to or use of or inability to access or use the Site;</li>
            <li>Any conduct or content of any third party on the Site;</li>
            <li>Any content obtained from the Site;</li>
            <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">8. Indemnification</h2>
          <p className="text-gray-700">
            You agree to defend, indemnify, and hold harmless Room Customiser and its affiliates, licensors, and service providers from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of or relating to your violation of these Terms or your use of the Site.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">9. Governing Law</h2>
          <p className="text-gray-700">
            These Terms shall be governed and construed in accordance with the laws of New South Wales, Australia, without regard to its conflict of law provisions.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">10. Changes to These Terms</h2>
          <p className="text-gray-700">
            Room Customiser reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.
          </p>
          <p className="text-gray-700 mt-2">
            By continuing to access or use our Site after those revisions become effective, you agree to be bound by the revised terms.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">11. Contact Us</h2>
          <p className="text-gray-700">
            If you have any questions about these Terms, please contact us at:
          </p>
          <p className="text-gray-700 mt-2">
            <strong>Email:</strong> support@roomcustomiser.com
          </p>
          <p className="text-gray-700">
            <strong>Address:</strong> 123 Custom Street, Sydney, NSW, Australia
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
