// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Landing from './Landing';
import Presets from './Presets';
import AddTarget from './pages/API/addTarget.js';
import Target from './pages/Target/Target.js';
import SlowestCalls from './SlowestCalls';
import Profile from './pages/Other/Profile';
import Gdpr from './pages/Other/gdpr-compliance.js';
import Login from './pages/Other/Login.js';
import Signup from './pages/Other/Signup.js';
import ApiTester from './pages/Tools/api-tester.js';
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Preview from './pages/Preview'
import Preset from './Preset'
import Upload from './pages/Upload/Upload.js'
import Privacy from './pages/Privacy.js'
import TermsOfService from './pages/TermsOfService.js'

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Landing />} />
            <Route path="/preset/preview/:presetId" element={<Preview />} />
            <Route path="/login" element={<Login />} />
            <Route path="/join" element={<Signup />} />
            <Route path="/gdpr" element={<Gdpr />} />
            <Route path="/gdpr" element={<Gdpr />} />
            <Route path="/tools/api-tester" element={<ApiTester />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/termsofservice" element={<TermsOfService />} />

            {/* Protected Routes */}
            <Route
              path="/presets"
              element={
                <ProtectedRoute>
                  <Presets />
                </ProtectedRoute>
              }
            />

            <Route
              path="/Preset/:presetId"
              element={
                <ProtectedRoute>
                  <Preset />
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}
