// src/pages/SettingsPage.js

import React, { useState, useContext, useEffect } from "react"
import {
  UserIcon,
  LockIcon,
  CreditCardIcon,
  ShieldIcon,
  LogOutIcon,
  AlertTriangleIcon,
  XIcon,
  MenuIcon,
  MailIcon,
  BarChart2,
  User,
} from 'lucide-react'
import { AuthContext } from '../../components/AuthContext.js'
import Footer from "../../components/Footer.js";

export default function SettingsPage() {
  const { user, setUser, checkAuth, logout } = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState("profile")
  const [fullName, setFullName] = useState(user?.full_name || "")
  const [email, setEmail] = useState(user?.email || "")
  const [isLoading, setIsLoading] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [contactMessage, setContactMessage] = useState("")

  const API_BASE_URL = 
      window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API;

  // Update form fields when user changes
  useEffect(() => {
    setFullName(user?.full_name || "")
    setEmail(user?.email || "")
  }, [user])

  const navItems = [
    { id: "profile", label: "Profile", icon: UserIcon },
    { id: "password", label: "Password", icon: LockIcon },
    { id: "contact", label: "Contact", icon: MailIcon },
  ]

  // Handler to update Full Name
  const handleUpdateFullName = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/fullname`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ full_name: fullName }),
      })
      if (response.ok) {
        const updatedUser = await response.json()
        setUser(updatedUser.user) // Update AuthContext with new user data
        checkAuth()
        alert("Full name updated successfully")
      } else {
        const errorData = await response.json()
        alert(`Error updating full name: ${errorData.error}`)
      }
    } catch (error) {
      console.error('Error updating full name:', error)
      alert("An error occurred while updating full name.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handler to update Email
  const handleUpdateEmail = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/user`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email }),
      })
      if (response.ok) {
        const updatedUser = await response.json()
        setUser(updatedUser.user) // Update AuthContext with new user data
        alert("Email updated successfully")
      } else {
        const errorData = await response.json()
        alert(`Error updating email: ${errorData.error}`)
      }
    } catch (error) {
      console.error('Error updating email:', error)
      alert("An error occurred while updating email.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handler to update Password
  const handleUpdatePassword = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const currentPassword = e.target.currentPassword.value
    const newPassword = e.target.newPassword.value
    const confirmPassword = e.target.confirmPassword.value

    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.")
      setIsLoading(false)
      return
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/user/password`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ currentPassword, newPassword }),
      })
      if (response.ok) {
        alert("Password updated successfully")
        e.target.reset()
      } else {
        const errorData = await response.json()
        alert(`Error updating password: ${errorData.error}`)
      }
    } catch (error) {
      console.error('Error updating password:', error)
      alert("An error occurred while updating password.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handler to log out from all devices
  const handleLogoutAllDevices = async () => {
    if (!window.confirm("Are you sure you want to log out from all devices?")) {
      return
    }
    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/logoutAll`, {
        method: 'POST',
        credentials: 'include',
      })
      if (response.ok) {
        alert("Logged out from all devices successfully.")
      } else {
        const errorData = await response.json()
        alert(`Error logging out from all devices: ${errorData.error}`)
      }
    } catch (error) {
      console.error('Error logging out from all devices:', error)
      alert("An error occurred while logging out from all devices.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handler to delete account
  const handleDeleteAccount = async () => {
    if (!window.confirm("Are you sure you want to delete your account? This action is irreversible.")) {
      return
    }
    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/user`, {
        method: 'DELETE',
        credentials: 'include',
      })
      if (response.ok) {
        alert("Account deleted successfully.")
        logout() // Clear AuthContext and redirect
      } else {
        const errorData = await response.json()
        alert(`Error deleting account: ${errorData.error}`)
      }
    } catch (error) {
      console.error('Error deleting account:', error)
      alert("An error occurred while deleting your account.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handler to submit contact form
  const handleContactSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email: user.email, message: contactMessage }),
      })
      if (response.ok) {
        alert("Message sent successfully.")
        setContactMessage("")
      } else {
        const errorData = await response.json()
        alert(`Error sending message: ${errorData.error}`)
      }
    } catch (error) {
      console.error('Error sending message:', error)
      alert("An error occurred while sending your message.")
    } finally {
      setIsLoading(false)
    }
  }

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <>
    <div className="flex flex-col md:flex-row bg-gray-100" style={{height: 'calc(100vh - 65px)'}} >
      {/* Mobile Menu Button */}
      <button
        className="md:hidden fixed top-4 left-4 z-50 p-2 bg-white rounded-md shadow-md"
        onClick={toggleMobileMenu}
        aria-label="Toggle menu"
      >
        {isMobileMenuOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
      </button>

      {/* Sidebar Navigation */}
      <div className={`fixed inset-y-0 left-0 transform ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out md:w-64 bg-white p-4 flex flex-col justify-between z-40`}>
        <nav className="space-y-2">
          {navItems.map((item) => {
            const Icon = item.icon
            return (
              <button
                key={item.id}
                onClick={() => {
                  setActiveTab(item.id)
                  setIsMobileMenuOpen(false)
                }}
                className={`w-full rounded-lg px-3 py-2 text-sm font-medium transition-colors flex items-center gap-2
                  ${activeTab === item.id 
                    ? "bg-black text-white"
                    : "hover:bg-gray-100 text-gray-600"
                  }`}
              >
                <Icon className="h-4 w-4" />
                {item.label}
              </button>
            )
          })}
        </nav>
        <button
          className="w-full mt-auto flex items-center justify-center gap-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
          onClick={logout}
        >
          <LogOutIcon className="h-4 w-4" />
          Logout
        </button>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6">
        {activeTab === "profile" && (
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Profile</h2>
            <div className="space-y-6">
              {/* Update Full Name */}
              <form onSubmit={handleUpdateFullName} className="max-w-2xl">
                <h3 className="text-lg font-medium mb-2">Update Full Name</h3>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
                    <input
                      type="text"
                      id="fullName"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 disabled:opacity-50"
                  >
                    {isLoading ? "Updating..." : "Save Full Name"}
                  </button>
                </div>
              </form>

              {/* Update Email */}
              <form onSubmit={handleUpdateEmail} className="max-w-2xl">
                <h3 className="text-lg font-medium mb-2">Update Email</h3>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 disabled:opacity-50"
                  >
                    {isLoading ? "Updating..." : "Save Email"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {activeTab === "password" && (
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Change Password</h2>
            <form onSubmit={handleUpdatePassword} className="max-w-2xl">
              <div className="space-y-4">
                <div>
                  <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">Current Password</label>
                  <input
                    type="password"
                    id="currentPassword"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">New Password</label>
                  <input
                    type="password"
                    id="newPassword"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 disabled:opacity-50"
                >
                  {isLoading ? "Updating..." : "Update Password"}
                </button>
              </div>
            </form>
          </div>
        )}

        {activeTab === "contact" && (
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <form onSubmit={handleContactSubmit} className="max-w-2xl">
              <div className="space-y-4">
                <div>
                  <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    id="contactEmail"
                    value={user?.email || ""}
                    readOnly
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 cursor-not-allowed"
                  />
                </div>
                <div>
                  <label htmlFor="contactMessage" className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea
                    id="contactMessage"
                    value={contactMessage}
                    onChange={(e) => setContactMessage(e.target.value)}
                    required
                    rows="4"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 disabled:opacity-50"
                >
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
    <Footer top_color={'#eeeeee'}/>
    </>
  )
}
