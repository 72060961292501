import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Check } from "lucide-react";

import { AuthContext } from "./components/AuthContext.js";

const baseDomain =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API;

const fetchPresets = async () => {
  const res = await fetch(baseDomain + "/api/frontpagepresets");
  if (!res.ok) {
    throw new Error("Failed to fetch presets");
  }
  const presets = await res.json();
  return presets;
};

const LandingPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  if (user?.id) navigate("/presets");

  const [loading, setLoading] = useState(true);
  const [presets, setPresets] = useState([]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await fetchPresets();
        if (data) setPresets(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="relative mt-20">
      <div
        className="min-h-screen flex flex-col items-center relative w-screen -mt-20 pb-24"
        style={{ background: "#eeeeee" }}
      >
        <section className="py-8 pb-12 rounded-lg text-center w-full relative flex flex-col items-center justify-center">
          <h2 className="text-4xl font-bold text-center text-gray-900  max-w-5xl">
            Welcome, explore our designs:{" "}
          </h2>
        </section>

        <div className="container mx-auto px-4 pb-2">
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
            // initial="hidden"
            animate="visible"
          >
            {presets.map((preset, index) => (
              <motion.div
                key={preset.name}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden p-2 bg-white cursor-pointer"
                // variants={itemVariants}
                onClick={() => navigate("/preset/" + preset.id)}
              >
                <div className="relative overflow-hidden">
                  <img
                    src={preset.image}
                    alt={preset.name}
                    className="w-full h-64 object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                  />
                </div>
                <div className="pt-4 bg-white">
                  <h3 className="text-xl text-center font-semibold text-gray-800">
                    {preset.name}
                  </h3>
                  <div className="flex justify-between items-center text-sm text-gray-600 mt-2">
                    <p>
                      <strong>Number of Items:</strong> {preset.furniture}
                    </p>
                    <p className="text-right">
                      <strong>Total Price:</strong> ${preset.total_cost}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>

{/*
          <div className="flex space-x-4 justify-center mt-12">
            <button
              className="less_cool_button_with_shadow py-2 px-4 rounded-2xl relative"
              onClick={() => (window.location.href = "/whatsmystylequiz")}
            >
              Load more designs
            </button>
          </div>
*/}

        </div>
      </div>

      <footer className="flex flex-col items-center justify-center relative w-screen bg-purple-600">
        {/* The line */}
        <div
          className="absolute top-0 left-0 w-full bg-black"
          style={{ height: "2.4px" }}
        ></div>
        {/* The SVG positioned so its top aligns with the line */}
        <div className="absolute top-[-5.7px] left-1/2 transform -translate-x-1/2">
          <svg
            width="600px"
            height="80px"
            viewBox="0 0 2129 257"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                className="Artboard"
                transform="translate(-559.000000, -1858.000000)"
              >
                <g
                  className="small_d3"
                  transform="translate(559.000000, 1858.000000)"
                >
                  <rect
                    className="Rectangle"
                    fillOpacity="0"
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="2129"
                    height="257"
                  ></rect>
                  <path
                    d="M1938.5,0 L1938.5,10.43 L1938.25,10.91 L1842.25,10.91 C1795.91,10.91 1714.15,54.42 1622.36,83.43 C1511.43,118.48 1394.25,153.9 1280.58,129.08 C1212.83,114.29 1104.75,80.49 1036.58,67.96 C874.75,38.22 709.76,141.9 548.44,109.49 C443.01,88.32 355.08,10.91 248.25,10.91 L190,10.91 L190,0 L1938.5,0 Z"
                    className="filling"
                    fill="#eeeeee"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M190.25,10.91 L248.25,10.91 C355.08,10.91 443.01,88.32 548.41,109.49 C709.73,141.9 874.72,38.22 1036.55,67.96 C1104.76,80.49 1212.83,114.29 1280.55,129.08 C1394.21,153.9 1511.4,118.48 1622.33,83.43 C1714.12,54.43 1795.89,10.91 1842.22,10.91 L1938.22,10.91"
                    className="border"
                    stroke="#000000"
                    strokeWidth="9"
                    strokeLinecap="round"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>

        {/* Footer */}
        <div className="text-white w-full mt-20 py-4 px-6 text-center">
          <div className="bg-black text-white w-full rounded-xl px-6 text-center p-4">
            <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between">
              <div className="mb-6 md:mb-0">
                <h4 className="text-xl font-bold mb-2">RoomCustomiser</h4>
                <p className="text-sm">Making every space feel like home.</p>
              </div>
              <div className="flex flex-col space-y-2 mb-6 md:mb-0">
                <a href="#" className="text-sm hover:underline">
                  Privacy Policy
                </a>
                <a href="#" className="text-sm hover:underline">
                  Terms of Service
                </a>
              </div>
              <div className="flex flex-col space-y-2">
                <p className="text-sm">
                  © 2024 RoomCustomiser. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;