import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="mx-auto px-4 py-8 bg-[#f9f6f3] w-screen">
      <div className="max-w-6xl mx-auto rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-4 text-gray-800">Privacy Policy</h1>

        <p className="mb-4 text-gray-700">
          Last updated: January 26, 2025
        </p>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">1. Introduction</h2>
          <p className="text-gray-700">
            Welcome to Room Customiser ("we", "our", "us"). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [roomcustomiser.com], including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”).
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">2. Information We Collect</h2>
          <p className="text-gray-700">
            We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, and in connection with other activities, services, features, or resources we make available on our Site.
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>Name</li>
            <li>Email Address</li>
            <li>Phone Number</li>
            <li>Usage Data</li>
            <li>Cookies and Tracking Technologies</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">3. How We Use Collected Information</h2>
          <p className="text-gray-700">
            Room Customiser may collect and use Users' personal information for the following purposes:
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>To provide and maintain our Service</li>
            <li>To improve customer service</li>
            <li>To personalize user experience</li>
            <li>To send periodic emails</li>
            <li>To process transactions</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">4. Legal Basis for Processing Personal Data (GDPR)</h2>
          <p className="text-gray-700">
            If you are from the European Economic Area (EEA), Room Customiser's legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">5. Data Protection Rights Under Australian Law</h2>
          <p className="text-gray-700">
            Under the Privacy Act 1988 (Cth), Australian Privacy Principles (APPs) govern the handling of personal information. We ensure that:
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>We only collect personal information that is necessary for our functions or activities.</li>
            <li>We manage personal information in an open and transparent way.</li>
            <li>We take reasonable steps to ensure the personal information we collect is accurate, complete, and up-to-date.</li>
            <li>We protect personal information from misuse, interference, loss, unauthorized access, modification, or disclosure.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">6. Sharing Your Personal Information</h2>
          <p className="text-gray-700">
            We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">7. Security of Your Information</h2>
          <p className="text-gray-700">
            We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">8. Your Data Protection Rights</h2>
          <p className="text-gray-700">
            Depending on your location, you may have the following rights regarding your personal information:
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>The right to access – You have the right to request copies of your personal data.</li>
            <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate.</li>
            <li>The right to erasure – You have the right to request that we erase your personal data.</li>
            <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data.</li>
            <li>The right to object to processing – You have the right to object to our processing of your personal data.</li>
            <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">9. Changes to This Privacy Policy</h2>
          <p className="text-gray-700">
            Room Customiser has the discretion to update this privacy policy at any time. We encourage Users to frequently check this page for any changes.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">10. Contact Us</h2>
          <p className="text-gray-700">
            If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:
          </p>
          <p className="text-gray-700 mt-2">
            <strong>Email:</strong> support@roomcustomiser.com
          </p>
{/*          <p className="text-gray-700">
            <strong>Address:</strong> 123 Custom Street, Sydney, NSW, Australia
          </p>*/}
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
