import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Check } from "lucide-react";

import { AuthContext } from "./components/AuthContext.js";
import LoadingAnimation from "./components/LoadingAnimation.js";
import Footer from "./components/Footer.js";

const baseDomain =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API;

const designOptions = [
  { id: 1, name: "Contemporary Chic", image: "/assets/LT01.png" },
  { id: 2, name: "Rustic Warmth", image: "/assets/CS01.png" },
  { id: 3, name: "Minimalist Zen", image: "/assets/CH01.png" },
  { id: 4, name: "Minimalist Zen", image: "/assets/VS04.png" },
]

const arrow_right = () => {
  return (
    <div className="half_cool_border py-2 px-6 rounded-full relative border-2 border-black mx-12">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-move-right"
      >
        <path d="M18 8L22 12L18 16"></path>
        <path d="M2 12H22"></path>
      </svg>
    </div>
  );
};

const fetchPresets = async () => {
  const res = await fetch(baseDomain + "/api/frontpagepresets");
  if (!res.ok) {
    throw new Error("Failed to fetch presets");
  }
  const presets = await res.json();
  return presets;
};

const LandingPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [presets, setPresets] = useState([]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await fetchPresets();
        if (data) setPresets(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (user?.id) navigate("/presets");
  }, [user]);

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      <main className="flex min-h-[95vh] flex-col items-center justify-center text-center px-4 relative z-1">
        <h1 className="text-5xl font-bold text-gray-900 leading-tight z-50">
          Design your dream living room.
          <br /> Perfect for renters.
        </h1>
        <p className="text-4xl text-purple-400 italic mt-4">
          🇦🇺 Based in Australia 🇦🇺
        </p>
        <p className="text-lg text-gray-600 mt-8 max-w-xl">
          We understand the pain of going through hundreds of stores and
          combining furniture, so we made it easy and enojoybale.
        </p>

        <div className="flex space-x-4 mt-4">
          <button
            className="less_cool_button_with_shadow py-2 px-4 rounded-full relative"
            onClick={() => (window.location.href = "/whatsmystylequiz")}
          >
            Jump to our Furniture Selection
          </button>
        </div>

        <div className="w-[80vw] hidden md:flex z-0">
          {designOptions.map((item, index) => (
            <motion.div
              key={item.id}
              className="absolute w-48 h-auto"
              style={{
                top: `${10 + index * 16}%`,
                left: `${index % 2 === 0 ? "0%" : "85%"}`,
                transform: "translate(-50%, -50%)",
                opacity: 0.9,
                zIndex: 0,
              }}
              animate={{ y: [0, -15, 0] }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                delay: index * 0.5,
              }}
            >
              <img
                src={item.image}
                alt={item.name}
                className="w-full h-full object-cover rounded-md"
              />
            </motion.div>
          ))}
        </div>
      </main>

      <div
        className="flex flex-col items-center justify-center relative w-screen -mt-20 pb-24"
        style={{ background: "#eeeeee" }}
      >
        {/* The line */}
        <div
          className="absolute top-0 left-0 w-full bg-black"
          style={{ height: "2.4px" }}
        ></div>
        {/* The SVG positioned so its top aligns with the line */}
        <div className="absolute top-[-5.7px] left-1/2 transform -translate-x-1/2">
          <svg
            width="600px"
            height="80px"
            viewBox="0 0 2129 257"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                className="Artboard"
                transform="translate(-559.000000, -1858.000000)"
              >
                <g
                  className="small_d3"
                  transform="translate(559.000000, 1858.000000)"
                >
                  <rect
                    className="Rectangle"
                    fillOpacity="0"
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="2129"
                    height="257"
                  ></rect>
                  <path
                    d="M1938.5,0 L1938.5,10.43 L1938.25,10.91 L1842.25,10.91 C1795.91,10.91 1714.15,54.42 1622.36,83.43 C1511.43,118.48 1394.25,153.9 1280.58,129.08 C1212.83,114.29 1104.75,80.49 1036.58,67.96 C874.75,38.22 709.76,141.9 548.44,109.49 C443.01,88.32 355.08,10.91 248.25,10.91 L190,10.91 L190,0 L1938.5,0 Z"
                    className="filling"
                    fill="#f8f6f3"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M190.25,10.91 L248.25,10.91 C355.08,10.91 443.01,88.32 548.41,109.49 C709.73,141.9 874.72,38.22 1036.55,67.96 C1104.76,80.49 1212.83,114.29 1280.55,129.08 C1394.21,153.9 1511.4,118.48 1622.33,83.43 C1714.12,54.43 1795.89,10.91 1842.22,10.91 L1938.22,10.91"
                    className="border"
                    stroke="#000000"
                    strokeWidth="9"
                    strokeLinecap="round"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>



        <h2 className="text-3xl font-bold text-center text-gray-900  max-w-4xl mt-20 pb-12">
          We built over 30 living rooms with different styles to help you find the right one:
        </h2>
        <div className="container mx-auto px-4 pb-2">
          {loading && <LoadingAnimation />}
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
            // variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {presets.map((preset, index) => (
              <motion.div
                key={preset.name}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden p-2 bg-white cursor-pointer"
                // variants={itemVariants}
                onClick={() => navigate("/preset/preview/" + preset.id)}
              >
                <div className="relative overflow-hidden">
                  <img
                    src={preset.image}
                    alt={preset.name}
                    className="w-full h-64 object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                  />
                </div>
                <div className="pt-4 bg-white">
                  <h3 className="text-xl text-center font-semibold text-gray-800">
                    {preset.name}
                  </h3>
                  <div className="flex justify-between items-center text-sm text-gray-600 mt-2">
                    <p>
                      <strong>Number of Items:</strong> {preset.furniture}
                    </p>
                    <p className="text-right">
                      <strong>Total Price:</strong> ${preset.total_cost}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>

          <div className="flex space-x-4 justify-center mt-12">
            <button
              className="less_cool_button_with_shadow py-2 px-4 rounded-2xl relative"
              onClick={() => (window.location.href = "/join")}
            >
              Load more designs
            </button>
          </div>
        </div>


        <section className="py-8 pt-12 pb-12 mt-8 rounded-lg text-center w-full relative flex flex-col items-center justify-center">
          {/*<h2 className="text-3xl font-bold text-center text-gray-900  max-w-5xl">We understand the <span className="text-purple-400 italic">pain</span> of going through hundreds of stores and combining furniture, so we made it <span className="text-purple-400 italic">easy</span> and <span className="text-purple-400 italic">enojoybale</span>.</h2>*/}

          <section className="cool_border bg-white px-12 py-12 rounded-lg shadow-md max-w-6xl w-full border-2 border-black relative">
            {/*
              <h2 className="text-4xl font-bold text-center text-gray-900 py-0 my-0">
                We found the <span className="text-purple-400 italic">best</span>{" "}
                furnitures in{" "}
                <span className="text-purple-400 italic">Australia</span>, and
                turned them into beautiful living rooms.
              </h2>
            */}
            <h2 className="text-5xl font-bold text-center text-gray-900 mb-24 -mt-24 underline">
              <br /> <br /> Here's how it works:
            </h2>
            <div className="flex items-center justify-center">
              <div className="flex flex-col items-center">
                <img
                  src="/assets/DESIGN OPTION 3A.jpg"
                  alt="Style Icon"
                  className="w-auto h-48 mb-4"
                />
                <h3 className="text-center text-3xl font-bold text-gray-900 mb-2 pt-4">
                  Click any room style you like
                </h3>
                <p className="text-center max-w-xs pt-12">
                  Explore a variety of styles to find the perfect look for your
                  space. Then click on it to get a more detailed overview.
                </p>
              </div>
              {arrow_right()}
              <div className="flex flex-col items-center px-4">
                <img
                  src="/assets/list.png"
                  alt="Shopping Icon"
                  className="w-auto h-48 mb-4"
                />
                <h3 className="text-center text-3xl font-bold text-gray-900 mb-2 pt-4">
                  See a list of all furniture in that style
                </h3>
                <p className="text-center max-w-xs pt-12">
                  See how to order each item in the design. We only use items
                  that are available to order in Australia. It's that easy.
                </p>
              </div>
            </div>
          </section>

        </section>
      </div>

      <Footer top_color={'#eeeeee'}/>
    </div>
  );
};

export default LandingPage;