import React, {useState, useEffect, useContext} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Heart, ChevronLeft, Star, Ruler, ChevronDown, Settings, CreditCard, FileText, LogOut } from "lucide-react"

import { AuthContext } from './components/AuthContext.js'
import LoadingScreen from './components/LoadingScreen'

const baseDomain =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API

const furnitureItems = [
  { code: 'SF01', type: 'sofa', name: 'Modern Gray Sofa', price: 1200, image: '/assets/SF01_Lounge Lovers_Draper Velvet 3 Seat Sofa.png', thumbnail: '/assets/mini/SF01_Lounge Lovers_Draper Velvet 3 Seat Sofa.png', width: 840, height: 345 },
  { code: 'CH01', type: 'chair', name: 'Accent Armchair', price: 400, image: '/assets/CH01.png', thumbnail: '/assets/mini/CH01.png', width: 817, height: 931 },
  { code: 'TA01', type: 'coffee_table', name: 'Round Coffee Table', price: 250, image: '/assets/TA01_Lounge Lovers_Serena Round Coffee Table.png', thumbnail: '/assets/mini/TA01_Lounge Lovers_Serena Round Coffee Table.png', width: 200, height: 89 },
  { code: 'RG01', type: 'rug', name: 'Patterned Area Rug', price: 300, image: '/assets/RG01.png', thumbnail: '/assets/mini/RG01.png', width: 356, height: 511 },
  { code: 'LF01', type: 'floor_lamp', name: 'Arc Floor Lamp', price: 150, image: '/assets/LF01.png', thumbnail: '/assets/mini/LF01.png', width: 50, height: 150 },
  { code: 'AT01', type: 'artwork', name: 'Abstract Wall Art', price: 200, image: '/assets/AT01.png', thumbnail: '/assets/mini/AT01.png', width: 200, height: 133 },
  { code: 'CS01', type: 'cushion', name: 'Decorative Cushion', price: 30, image: '/assets/CS01.png', thumbnail: '/assets/mini/CS01.png', width: 111, height: 111 },
]

function LivingRoomDesign() {
const baseDomain =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.PUBLIC_API

  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  if(!user?.id) navigate('/login')

  const { presetId } = useParams()
  const [loading, setLoading] = useState(true)
  const [preset, setPreset] = useState({})
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('presets')

  useEffect(() => {
      window.scrollTo(0, 0)
  }, [60])

  const fetchPreset = async (presetId) => {
    setLoading(true)
    const res = await fetch(baseDomain+"/api/presets/"+presetId)
    if (!res.ok) {
      throw new Error("Failed to fetch presets");
    }
    const x = await res.json()
    console.log(x)
    setPreset(x)
    setLoading(false)
  }

  useEffect(() => {
    fetchPreset(presetId)
  }, [presetId, user?.id])

  if(loading) return <LoadingScreen/>


  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col">
        {/* Header */}
      <header className="flex justify-between items-center w-full p-6">
        <a href="/presets" className="text-purple-800 hover:underline">
          &larr; Back 
        </a>
      </header>

          <div className="md:w-full p-4 flex flex-col justify-center items-center">
              <img src={preset.image} alt="Living Room Design" className="w-full h-full max-w-4xl object-cover rounded-lg" />
              <h1 className="text-2xl font-bold mb-2 text-center pt-8">{preset.name}</h1>
          </div>


        {/* Furniture List */}
        <div className="p-4 mt-4">

      <div className="overflow-x-auto px-12">
        <h3 className="text-2xl font-semibold mb-2 pt-8">List to order the furniture</h3>

        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Furniture</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {preset.furniture.map((item, index) => (
              <tr key={index} className="transition duration-300 ease-in-out hover:bg-gray-50">
                <td className="py-4 px-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{item['PRODUCT NAME']}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-center">
                  <div className="text-sm text-gray-500">{item.WIDTH}x{item.HEIGHT}x{item.DEPTH}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-center">
                  <div className="text-sm font-semibold text-gray-900">{item.COST}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-sm font-medium text-center">
                  <a
                    href={item.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cool_button bg-purple-300 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out inline-block cursor-pointer"
                  >
                    Link To Order Item
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

        </div>


    </div>
  )
}

export default LivingRoomDesign