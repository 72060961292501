import React, { useRef, useState } from "react";
import { uploadImage } from "./api";

const PresetModal = ({
  isOpen,
  onClose,
  preset,
  setPreset,
  onSubmit,
  isEditing,
  loading,
  onAddFurniture,
  onRemoveFurniture,
  onFurnitureChange,
}) => {
  const fileInputRef = useRef(null);
  const [uploadingFurnitureId, setUploadingFurnitureId] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);

  // Handle preset image replacement
  const handleReplacePresetImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreset((prev) => ({ ...prev, image: null, imagePreview: null }));
      setImageUploading(true);

      try {
        const result = await uploadImage(file);
        setPreset((prev) => ({
          ...prev,
          image: result.imageUrl,
          imagePreview: result.imageUrl,
        }));
      } catch (err) {
        console.error("Error uploading image:", err);
        alert("Failed to upload image. Please try again.");
      } finally {
        setImageUploading(false);
      }
    }
  };

  // Handle furniture image upload
  const handleFurnitureImageUpload = async (fid, e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadingFurnitureId(fid);

      try {
        const result = await uploadImage(file);

        setPreset((prev) => ({
          ...prev,
          furniture: prev.furniture.map((item) =>
            item.id === fid ? { ...item, image: result.imageUrl } : item
          ),
        }));
      } catch (err) {
        console.error("Error uploading furniture image:", err);
        alert("Failed to upload image. Please try again.");
      } finally {
        setUploadingFurnitureId(null);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-md shadow-lg w-full max-w-5xl max-h-screen overflow-y-auto p-6 relative">
        {loading ? (
          <div className="text-center py-6">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-lg font-semibold">Loading...</p>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-4">
              {isEditing ? "Edit Preset" : "Create a New Preset"}
            </h2>

            <label className="block font-semibold mb-1">Preset Name:</label>
            <input
              type="text"
              className="w-full border rounded px-3 py-2 mb-4"
              value={preset.name}
              onChange={(e) => setPreset({ ...preset, name: e.target.value })}
            />

            <label className="block font-semibold mb-1">Preset Image:</label>
            {imageUploading ? (
              <div className="flex justify-center items-center h-32 w-full border border-gray-300 rounded">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
                <p className="ml-4 text-blue-600 font-semibold">Uploading...</p>
              </div>
            ) : preset.imagePreview || preset.image ? (
              <div className="flex flex-col items-center">
                <img
                  src={preset.imagePreview || preset.image}
                  alt="Preset Preview"
                  className="mt-2 rounded border max-w-xs"
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleReplacePresetImage}
                />
                <button
                  onClick={() => fileInputRef.current.click()}
                  className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded mt-4"
                >
                  Replace Image
                </button>
              </div>
            ) : (
              <input type="file" accept="image/*" onChange={handleReplacePresetImage} className="mb-4" />
            )}

            <h3 className="text-xl font-semibold mt-4 mb-2">Furniture Items</h3>
            <button
              onClick={onAddFurniture}
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded mb-4"
            >
              + Add Furniture
            </button>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {preset.furniture?.map((item) => (
                <div key={item.id} className="border rounded-lg p-4 shadow hover:shadow-lg transition-shadow">
                  <div className="flex flex-col items-center">
                    {uploadingFurnitureId === item.id ? (
                      <div className="flex justify-center items-center h-32 w-full border border-gray-300 rounded">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
                        <p className="ml-4 text-blue-600 font-semibold">Uploading...</p>
                      </div>
                    ) : item.image ? (
                      <img
                        src={item.image}
                        alt="Furniture"
                        className="rounded border w-full h-32 object-contain"
                      />
                    ) : (
                      <div className="w-full h-32 border border-gray-300 flex items-center justify-center text-gray-500">
                        No Image
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      id={`file-input-${item.id}`}
                      onChange={(e) => handleFurnitureImageUpload(item.id, e)}
                    />
                    <button
                      onClick={() => document.getElementById(`file-input-${item.id}`).click()}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mt-2"
                    >
                      {item.image ? "Replace Image" : "Upload Image"}
                    </button>
                  </div>

                  {/* Furniture item fields */}
                  {["CODE", "PRODUCT NAMES", "FINISH/COLOUR", "SUPPLIER", "LENGTH", "HEIGHT", "DEPTH", "COST", "URL"].map((field) => (
                    <input
                      key={field}
                      type="text"
                      className="w-full border rounded px-3 py-2 mt-2"
                      placeholder={field}
                      value={item[field]}
                      onChange={(e) => onFurnitureChange(item.id, field, e.target.value)}
                    />
                  ))}

                  <button
                    onClick={() => onRemoveFurniture(item.id)}
                    className="text-red-600 hover:text-red-800 font-semibold mt-4"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>

            <div className="mt-6 flex space-x-2">
              <button
                onClick={onSubmit}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
              >
                {isEditing ? "Save Changes" : "Create"}
              </button>
              <button
                onClick={onClose}
                className="border border-gray-300 hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PresetModal;
