// src/api.js

const baseDomain =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API

/**
 * Fetch all presets from /api/presets
 */
export async function fetchPresets() {
  const res = await fetch(baseDomain+"/api/presets");
  if (!res.ok) {
    throw new Error("Failed to fetch presets");
  }
  const presets = await res.json()
  console.log('presets: ', presets)
  return presets
}

/**
 * Fetch a single preset by ID, from /api/presets/:id
 * @param {number} presetId 
 */
export async function fetchPresetById(presetId) {
  const res = await fetch(baseDomain+`/api/presets/${presetId}`);
  if (!res.ok) {
    throw new Error(`Failed to fetch preset ${presetId}`);
  }
  return await res.json();
}

/**
 * Create a new preset with JSON data (name, furniture JSON, imageUrl)
 * @param {Object} presetData 
 */
export async function createPreset(presetData) {
  const res = await fetch(baseDomain + "/api/presets", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(presetData),
  });

  if (!res.ok) {
    throw new Error("Failed to create new preset");
  }
  return await res.json();
}

/**
 * Update an existing preset by ID, with JSON data
 * @param {number} presetId 
 * @param {Object} presetData 
 */
export async function updatePreset(presetId, presetData) {
  const res = await fetch(baseDomain + `/api/presets/${presetId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(presetData),
  });

  if (!res.ok) {
    throw new Error(`Failed to update preset ${presetId}`);
  }
  return await res.json();
}


/**
 * Delete a preset by ID
 * @param {number} presetId 
 */
export async function deletePreset(presetId) {
  const res = await fetch(baseDomain+`/api/presets/${presetId}`, {
    method: "DELETE",
  });
  if (!res.ok) {
    throw new Error(`Failed to delete preset ${presetId}`);
  }
  return await res.json();
}

/**
 * Upload a single image and return the image URL
 * @param {File} imageFile 
 */
export async function uploadImage(imageFile) {
  const formData = new FormData();
  formData.append("image", imageFile);

  const res = await fetch(baseDomain + "/api/upload/singleimage", {
    method: "POST",
    body: formData,
  });

  if (!res.ok) {
    throw new Error("Failed to upload image");
  }

  return await res.json();  // Expected response: { imageUrl: "https://uploaded-image-url.com" }
}