// src/contexts/AuthContext.js
import LoadingScreen from './LoadingScreen'
import React, { useEffect, createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  useEffect(() => {
    const cachedUser = localStorage.getItem('user');
    const parsedUser = cachedUser ? JSON.parse(cachedUser) : {}
    setUser(parsedUser)
    setLoading(false)
  }, []);

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true); // Set loading based on cache

  if(loading) return <LoadingScreen/>

  console.log('user ', user)

  const API_BASE_URL = 
    window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API;
    
  // Function to log out the user
  const logout = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/logout`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        setUser(null);
        localStorage.removeItem('user');
        window.location.href = '/'; // Redirect to front page
      } else {
        console.error('Failed to logout.');
        // Even if logout fails, clear user data
        setUser(null);
        localStorage.removeItem('user');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Logout error:', error);
      // On error, still clear user data
      setUser(null);
      localStorage.removeItem('user');
      window.location.href = '/';
    }
  };

  // Function to check authentication status
  const checkAuth = async () => {
    // if(!user) return
    setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/auth/me`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('user', JSON.stringify(data.user));
      } else if (response.status === 401) {
        // Unauthorized - perform logout
        console.log('LOGGGGOUTTTT')
        console.warn('Unauthorized! Logging out...');
        await logout();
      } else {
        // Other errors
        console.error('Failed to authenticate.');
        setUser(null);
        localStorage.removeItem('user');
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      setUser(null);
      localStorage.removeItem('user');
    } finally {
      setLoading(false);
    }
  };

  // Function to log out all devices
  const logoutAllDevices = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/logoutAll`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        setUser(null);
        localStorage.removeItem('user');
        window.location.href = '/';
        alert("Logged out from all devices successfully.");
        // Optionally, you can also clear user data and redirect
      } else {
        console.error('Failed to logout from all devices.');
      }
    } catch (error) {
      console.error('Error logging out from all devices:', error);
    }
  };

  // Function to delete account
  const deleteAccount = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (response.ok) {
        setUser(null);
        localStorage.removeItem('user');
        window.location.href = '/'; // Redirect to home after account deletion
      } else {
        console.error('Failed to delete account.');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  // useEffect(() => {
  //   checkAuth();
  // }, [user]);

  return (
    <AuthContext.Provider value={{ 
      user, 
      setUser, 
      loading, 
      logout, 
      logoutAllDevices, 
      deleteAccount, 
      checkAuth 
    }}>
      {children}
    </AuthContext.Provider>
  );
};
